import "./contact.scss";
import MailIcon from "@mui/icons-material/Mail";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

export default function Contact() {
    return (
        <div className="contact" id="contact">
            <div className="section_title">
                <ImportContactsIcon className="title_icon" />
                <h1>Contact</h1>
            </div>
            <div className="container">
                <div className="contact_item">
                    <LocalPhoneIcon className="contact_item_icon" />
                    <h2>+90 (545) 177 25 26</h2>
                </div>
                <div className="contact_item">
                    <MailIcon className="contact_item_icon" />
                    <h2>
                        <a href="mailto: tnehirdogan@gmail.com">
                            tnehirdogan@gmail.com
                        </a>
                    </h2>
                </div>
                <div className="contact_item">
                    <LinkedInIcon className="contact_item_icon" />
                    <h2>
                        <a
                            href="https://www.linkedin.com/in/tndogan/"
                            target="_blank"
                        >
                            Taskin Nehir Dogan
                        </a>
                    </h2>
                </div>
            </div>
        </div>
    );
}
